// import { Spin } from "antd"
import React from "react"
import { useRoutes } from "react-router-dom"
import ROUTER from "./index"
import { BallTriangle } from "react-loader-spinner"

// ANONYMOUS
const PublicRouters = React.lazy(() => import("src/pages/PublicRouters"))
const SvgViewer = React.lazy(() => import("src/pages/SvgViewer"))
const NotFound = React.lazy(() => import("src/pages/NotFound"))
const LoginPage = React.lazy(() => import("src/pages/ANONYMOUS/LoginPage"))
const RegisterPage = React.lazy(() => import("src/pages/ANONYMOUS/Register"))
const InfoAccount = React.lazy(() => import("src/pages/ANONYMOUS/InfoAccount"))
const SignPhone = React.lazy(() =>
  import("src/pages/ANONYMOUS/SendPhone/components/SignPhone"),
)

const HomeBackground = React.lazy(() =>
  import("src/pages/ANONYMOUS/HomeBackground"),
)
const WrongPage = React.lazy(() =>
  import("src/pages/ANONYMOUS/HomeBackground/components/WrongPage"),
)
const CompletePage = React.lazy(() =>
  import("src/pages/ANONYMOUS/HomeBackground/components/CompletePage"),
)
const SendPhone = React.lazy(() => import("src/pages/ANONYMOUS/SendPhone"))

// USER
const PrivateRoutes = React.lazy(() => import("src/pages/PrivateRoutes"))
const ChangePassword = React.lazy(() => import("src/pages/USER/ChangePassword"))

// ADMIN

function LazyLoadingComponent({ children }) {
  return (
    <React.Suspense
      fallback={
        <div className="loading-center" style={{ height: "100vh" }}>
          {/* <Spin /> */}
          <BallTriangle
            height={50}
            width={50}
            radius={5}
            color="#01638d"
            ariaLabel="ball-triangle-loading"
            wrapperClass={{}}
            wrapperStyle=""
            visible={true}
          />
        </div>
      }
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        {children}
      </div>
    </React.Suspense>
  )
}

const routes = [
  {
    path: ROUTER.SVG_VIEWER,
    element: (
      <LazyLoadingComponent>
        <SvgViewer />
      </LazyLoadingComponent>
    ),
  },
  // ADMIN
  //  USER
  {
    element: (
      <LazyLoadingComponent>
        <PrivateRoutes />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.DOI_MAT_KHAU,
        element: (
          <LazyLoadingComponent>
            <ChangePassword />
          </LazyLoadingComponent>
        ),
      },
    ],
  },

  //  ANONYMOUS
  {
    element: (
      <LazyLoadingComponent>
        <PublicRouters />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.HOME,
        element: (
          <LazyLoadingComponent>
            <SendPhone />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.PRODUCT,
        element: (
          <LazyLoadingComponent>
            <SendPhone />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.SIGN_PHONE,
        element: (
          <LazyLoadingComponent>
            <SignPhone />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.PRODUCTS,
        element: (
          <LazyLoadingComponent>
            <HomeBackground />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.ERROR,
        element: (
          <LazyLoadingComponent>
            <WrongPage />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.COMPLETE,
        element: (
          <LazyLoadingComponent>
            <CompletePage />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.DANG_NHAP,
        element: (
          <LazyLoadingComponent>
            <LoginPage />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANG_KY,
        element: (
          <LazyLoadingComponent>
            <RegisterPage />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.ACCOUNT,
        element: (
          <LazyLoadingComponent>
            <InfoAccount />
          </LazyLoadingComponent>
        ),
      },
    ],
  },
  // {
  //   path: "*",
  //   element: (
  //     <LazyLoadingComponent>
  //       <NotFound />
  //     </LazyLoadingComponent>
  //   ),
  // },
]
const AppRouter = () => {
  const renderRouter = useRoutes(routes)
  return renderRouter
}
export default AppRouter
