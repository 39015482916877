const ROUTER = {
  SVG_VIEWER: "/svg-viewer",
  HOME: "/",
  ERROR: "/error",
  COMPLETE: "/complete",
  PRODUCTS: "/products/:id",
  PRODUCT: "/products",
  SIGN_PHONE: "/signPhone",

  //ANONYMOUS

  // ADMIN
}
export default ROUTER
